import { api } from 'services';
import { AnnouncementService } from 'modules/announcement';
import { toQueryParams } from 'utils';
import Transformer from './transformers';

class LocationService {
    static get(id) {
        return api
            .get(`/locations/${id}`)
            .then(Transformer.in);
    }

    static async getByCid(cid) {
        return api.get(`/location/${cid}`)
            .then(Transformer.in);
    }

    static async getByDomain(subdomain) {
        return api.get(`/location/subdomain/${subdomain}`)
            .then(Transformer.in);
    }

    static remove(id) {
        return api
            .delete(`locations/${id}`);
    }

    static update(id, data) {
        return api
            .put(`/locations/${id}`, Transformer.out(data))

            .then(Transformer.in);
    }

    static list(...args) {
        return api.list('/locations', ...args)
            .then(Transformer.list);
    }

    static listInactive(...args) {
        return api.list('/locations/inactive', ...args)
            .then(Transformer.list);
    }

    static options(filters = {}) {
        const params = toQueryParams(filters);
        return api.get(`/locations/options?${params}`);
    }

    static getByFilters(value) {
        return LocationService.list(1, 10000, value)
            .then(data => data.data);
    }

    static announcements(id, data) {
        if (data) {
            return AnnouncementService.update(id, data);
        }
        return AnnouncementService.list(id);
    }

    static downloadPdf(id, template = 'poster', forceDownload = false, imgInlineView = false) {
        return api.download(`/locations/${id}/pdf/${template}`, forceDownload, imgInlineView);
    }
}

export { LocationService };
